import { Button, CircularProgress, TextField } from '@mui/material'
import { signIn } from 'next-auth/react'
import { ReactElement, Suspense, useState } from 'react'
import { getEmailDomain, isvalidEmail } from '../utils/stringutils'
import axios from 'axios'
import { NextPage } from 'next'
import { isClient } from '../utils/ssr'
import { Organization } from '../src/types/organization'

const getInitialEmail = () =>
  isClient() && window.localStorage ? localStorage.getItem('NGILIVE_LOGIN_EMAIL') ?? '' : ''

const Login: NextPage = () => {
  const initialEmail = getInitialEmail()
  const [invalidEmailError, setInvalidEmailError] = useState(!isvalidEmail(initialEmail))
  const [email, setEmail] = useState(initialEmail)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const login = async () => {
    setErrorMessage('')
    const emailDomain = getEmailDomain(email)
    setLoading(true)
    const orgResponse = await axios.get<Organization[]>(`/api/organizations?authDomain=${emailDomain}`)
    const authOrg = orgResponse.data.find((org) => org.auth_domain === emailDomain)
    const idpName = authOrg?.idp_name
    if (!idpName) {
      setErrorMessage('No organization found for this email!')
      setLoading(false)
    } else {
      setErrorMessage('')
      try {
        void signIn('keycloak', {}, { 'kc_idp_hint': idpName })
      } catch (e) {
        console.error(e)
        setErrorMessage('Error logging in')
        setLoading(false)
      }
    }
  }

  return (
    <div className="container flex-center flex-column flex-gap-16">
      <h1>NGI Live Admin</h1>
      <h2>Log in to continue:</h2>
      <TextField
        id="outlined-error-helper-text"
        error={invalidEmailError || (errorMessage !== '')}
        type="email"
        label="Email"
        placeholder="your@email.here"
        helperText={invalidEmailError ? 'Invalid email' : errorMessage ?? ''}
        onChange={(e) => {
          setEmail(e.target.value)
          setInvalidEmailError(!isvalidEmail(e.target.value))
        }}
        style={{ width: '320px' }}
        value={email}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            void login()
          }
        }}
      />
      <Button onClick={login} variant="contained" disabled={invalidEmailError}>
        {loading ? <CircularProgress/> : 'Log In'}
      </Button>
    </div>
  )
}

export default function LoginWrapper(): ReactElement {
  return (
    <>
      {isClient() ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Login></Login>
        </Suspense>
      ) : null}
    </>
  )
}
